<template>
    <!-- <div class="banner-container">
        <v-img v-if="altSrc && src" alt="Bannière" :src="src" :contain="true" />
    </div> -->
    <div class="banner-container">
        <progressive-background
            v-if="altSrc && src"
            class="banner"
            :src="src"
            :placeholder="altSrc"
            :blur="30"
        />
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'Banner',
    data() {
        return {
            src: '',
            altSrc: '',
        };
    },
    async created() {
        const res = await axios.get(process.env.VUE_APP_API_URL + '/banner');
        const { banner, banner_low: bannerLow } = res.data;
        this.altSrc = bannerLow.url;
        this.src = banner.url;
    },
};
</script>

<style>
.banner-container {
    background-color: gray;
    height: 30em;
}

@media screen and (max-width: 900px) {
    .banner {
        overflow-x: scroll !important;
        scrollbar-width: none;
    }

    .banner::-webkit-scrollbar {
        display: none;
    }
}

.banner {
    height: 30em !important;
    max-width: 100vw; /* No wider than 100% of the viewport width */
    width: 100%;
    display: block;
}

.banner span div {
    height: 30em;
    width: 1900px;
    background-position: center center;
}

.banner span div div {
    background-position: center center;
    width: 1900px;
    background-size: contain;
}
</style>
