<template>
    <div class="home">
        <Banner />

        <div class="presentation-container">
            <div class="container-alert">
                <v-alert
                    border="top"
                    colored-border
                    type="primary"
                    elevation="2"
                >
                    Notre nouveau livre <i>Le Vent du Nord</i>, par Benjamin
                    Calame est enfin commandable&nbsp; !&nbsp;Allez voir le
                    <a href="/opus/8">détail de l'ouvrage</a>.
                </v-alert>
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="container-content" v-html="content"></div>
            <picture>
                <source
                    :srcset="webp.url"
                    type="image/webp"
                    :height="webp.height"
                    :width="webp.width"
                />

                <img
                    id="team"
                    :src="img.url"
                    alt="Photo de groupe des membres de l'édition"
                    :height="img.height"
                    :width="img.width"
                />
            </picture>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/banner';
import axios from 'axios';
import marked from 'marked';
import DOMPurify from 'dompurify';

export default {
    name: 'Home',
    components: {
        Banner,
    },
    data() {
        return {
            content: '',
            img: {},
            webp: {},
        };
    },
    created: async function () {
        const res = await axios.get(
            process.env.VUE_APP_API_URL + '/presentation'
        );
        const { text, image, webp } = res.data;
        this.content = DOMPurify.sanitize(marked(text));
        this.img = {
            url: image.formats.medium.url,
            width: image.formats.medium.width,
            height: image.formats.medium.height,
        };

        this.webp = {
            url: webp.formats.medium.url,
        };
    },
};
</script>
<style>
.home {
    margin-top: -14px;
    margin-left: -12px;
    margin-right: -12px;
}

.presentation-container {
    padding: 50px;
}

.container-content {
    text-align: justify;
    align-content: center;
    display: block;
    width: 70%;
    margin: auto;
    padding: 50px;
    font-size: 22px;
}

.container-alert {
    text-align: justify;
    align-content: center;
    display: block;
    width: 70%;
    margin: auto;
    padding: 0px 50px;
    font-size: 22px;
}

#team {
    align-content: center;
    max-height: 30em;
    max-width: 35em;
    min-height: 10em;
    min-width: 15em;
}

@media screen and (max-width: 900px) {
    #team {
        align-content: center;
        height: 15em;
        width: 20em;
    }

    .container-content {
        padding: 0;
        margin: 0;
        font-size: 22px;
        width: 100%;
    }
}

.container-content p {
    margin-bottom: 20px;
}
</style>
